export enum AssistantType {
  SCHOLAR = 'scholar',
  HISTORY = 'history',
  STUDY = 'study',
  ALPHA = 'alpha',
  ORIGINAL_TEXT = 'original_text',
  GENERAL = 'general',
}

export enum Language {
  ENGLISH = 'EN',
  DUTCH = 'NL',
}

export const bibleVersions = ['KR92', 'NBV', 'NBV21', 'BGT', 'HSV']

export const bibleVersionsDutch = ['NBV21', 'NBV', 'BGT', 'HSV']
export const bibleVersionsFinnish = ['KR92', 'UT2020']
export const bibleVersionsEnglish = ['KJV']
export const bibleVersionsPortuguese = ['PORTUGUESE']

export enum BibleVersionIds {
  KR92 = '90eb7230d99068fd-02',
  UT2020 = '3b84bd97555bcf4f-02',
  KJV = '5cccfa61df57262f-01',
  NBV = 'a31452bf933bcd2d-01',
  NBV21 = '01b58d476a09e6a6-01',
  BGT = '173b6ec5bba026d2-01',
  HSV = '87f620660790371b-01',
}
